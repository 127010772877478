import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { colors, breakpoints, fonts } from '../../style-utilities/variables';
import StyledResultsIndex from './StyledResultsIndex.styles';
import ReactHtmlParser from 'react-html-parser';

//components
import Hero from '../../components/Hero';
import ContactBanner from '../../components/ContactBanner';
import Industries from '../../components/Results/Industries';
import SEO from '../../components/SEO';
import DefaultSharingImage from './../../static/ansira-logo-sharing.png';
import Related3Grid from './../../components/Related/Related3Grid';

class ResultsPage extends Component {
  state = {
    isPreviewMode: false,
    previewPayload: null
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined',
      viewPort: window.innerWidth >= 768 ? 'desktop' : 'mobile'
    });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.setViewport);
    }
    let search = this.props.location.search;
    let params = new URLSearchParams(search);

    history.pushState('', document.title, location.href.replace( /#.*/, ''));
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    const results_page = this.props.data.wordpressPage;
    const caseStudies = this.props.data.allWordpressWpCaseStudy.edges;

    const relatedCaseStudies = [];
    relatedCaseStudies.push(caseStudies[0].node);
    relatedCaseStudies.push(caseStudies[1].node);
    relatedCaseStudies.push(caseStudies[2].node);

    if (this.state.isPreviewMode) {
      results_page.title = this.state.previewPayload.title.rendered;
      results_page.content = this.state.previewPayload.content.rendered;
      results_page.acf = this.state.previewPayload.acf;
    }

    return (
      <StyledResultsIndex className="animate">
        <SEO
          isBlogPost={false}
          postData={this.props.data.wordpressPage}
          postImage={DefaultSharingImage}
        />
        <Hero
          headline={results_page.acf.hero_headline}
          sub={results_page.acf.hero_sub_headline}
        />
        <div className="page-container">
          <p className="intro-copy">
            {ReactHtmlParser(results_page.acf.intro_copy)}
          </p>
          <Related3Grid
            header="The Proof Is in the Case Studies"
            items={relatedCaseStudies}
            readAll="See All Case Studies"
            readAllLink="/results/case-studies/"
            gridCardType="noTags"
          />
          <Industries />
        </div>
        <ContactBanner
          headline={results_page.acf.contact_headline}
          cta={results_page.acf.contact_cta}
          url={results_page.acf.contact_url}
        />
      </StyledResultsIndex>
    );
  }
}
export const query = graphql`
  query {
    wordpressPage(slug: { eq: "results" }) {
      content
      slug
      title
      type
      content
      acf {
        seo_title
        seo_canonical
        seo_description
        hero_headline
        hero_sub_headline
        intro_copy
        contact_headline
        contact_cta
        contact_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    allWordpressWpCaseStudy {
      edges {
        node {
          title
          type
          type
          topic
          slug
          content
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            client
            excerpt
            featured_case_study
            quote_copy
            quote_author
            client_logo {
              source_url
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
          }
        }
      }
    }
  }
`;
export default ResultsPage;
