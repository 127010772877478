import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { colors, fonts, breakpoints } from "../../style-utilities/variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faCar,
  faBox,
  faUniversity,
  faBolt,
  faFilm,
  faHeartbeat,
  faConciergeBell,
  faClipboardList,
  faChartArea,
  faUtensils,
  faShoppingCart,
  faMicrochip,
  faBroadcastTower,
  faHotel
} from "@fortawesome/free-solid-svg-icons";

const Div = styled.div`
  margin-top: 3em;
  h2 {
    margin-bottom: 1.2em;
  }
  ul {
    margin-left: 0;
    margin-bottom: 0;
    svg {
      color: ${colors.blue};
      font-size: 26px;
      float: left;
      margin-top: 5px;
    }
    li {
      list-style: none;
      color: ${colors.primaryGray};
      font-size: 26px;
      margin-left: 1.6em;
      margin-bottom: 1.8rem;
      #text {
        margin-left: 20px;
      }
    }

  }
  @media (min-width: ${breakpoints.tablet}) {
    .icon-container {
      display: flex;
      justify-content: space-around;
      margin-bottom: 2em;
    }
    ul {
      width: 33%;
    }
    li:last-child {
      margin-bottom: 0;
    }
  }
`;
const Industries = () => {
  return (
    <Div>
      <h2>Industries We Serve</h2>
      <div className="icon-container">
        <ul>
          <FontAwesomeIcon icon={faCar} />
          <li>Automotive</li>
          <FontAwesomeIcon icon={faBriefcase} />
          <li>Business Services</li>
          <FontAwesomeIcon icon={faBox} />
          <li>Consumer Packaged Goods</li>
          <FontAwesomeIcon icon={faUniversity} />
          <li>Education</li>
          <FontAwesomeIcon icon={faBolt} />
          <li>Energy</li>
        </ul>
        <ul>
          <FontAwesomeIcon icon={faFilm} />
          <li>Entertainment</li>
          <FontAwesomeIcon icon={faHeartbeat} />
          <li>Healthcare</li>
          <FontAwesomeIcon icon={faConciergeBell} />
          <li>Hospitality</li>
          <FontAwesomeIcon icon={faClipboardList} />
          <li>Insurance</li>
          <FontAwesomeIcon icon={faChartArea} />
          <li>Manufacturing</li>
        </ul>
        <ul>
          <FontAwesomeIcon icon={faUtensils} />
          <li>Restaurants</li>
          <FontAwesomeIcon icon={faShoppingCart} />
          <li>Retail</li>
          <FontAwesomeIcon icon={faMicrochip} />
          <li>Technology</li>
          <FontAwesomeIcon icon={faBroadcastTower} />
          <li>Telecommunications</li>
          <FontAwesomeIcon icon={faHotel} />
          <li>Tourism</li>
        </ul>
      </div>
    </Div>
  );
};

export default Industries;
